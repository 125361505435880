import { render, staticRenderFns } from "./toSp.vue?vue&type=template&id=f1079ede&scoped=true&"
import script from "./toSp.vue?vue&type=script&lang=js&"
export * from "./toSp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1079ede",
  null
  
)

export default component.exports