<template>
  <div class="page">

    <div class="main">
      <el-row>
        <el-col :span="3">
          <el-select v-model="year" placeholder="选择年份" size="mini" @change="onYearChange">
            <el-option v-for="item in years" :key="item" :label="item + '年'" :value="item" />
          </el-select>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-button size="mini" @click.stop="prePage">上一页</el-button>
          <el-button size="mini">{{ pageNum }}/{{
            pageTotalNum }}</el-button>
          <el-button size="mini" @click.stop="nextPage">下一页</el-button>
        </el-col>
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="keyWord" clearable class="input-with-select" size="mini"
            @keyup.enter.native="search">
            <el-button slot="append" icon="el-icon-search" @click="search()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button style="margin-left: 10px;" size="mini" @click="nestSearch">下一个</el-button>
        </el-col>
      </el-row>

    </div>
    <div class="pdf">
      <div class="show">
        <pdf ref="pdf" :src="pdfUrl" :page="pageNum" :rotate="pageRotate" @progress="loadedRatio = $event"
          @page-loaded="pageLoaded($event)" @num-pages="pageTotalNum = $event" @error="pdfError($event)"
          @link-clicked="page = $event">
        </pdf>
      </div>

      <!-- <div class="pdf_footer">
        <div class="info">
          <div>当前页数/总页数：{{ pageNum }}/{{ pageTotalNum }}</div>
          <div>进度：{{ loadedRatio }}</div>
          <div>页面加载成功: {{ curPageNum }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import pdf from "vue-pdf";
export default {
  name: "vue_pdf_preview",

  components: {
    pdf,
  },
  data() {
    return {
      // 总页数
      pageTotalNum: 1,
      // 当前页数
      pageNum: 1,
      // 加载进度
      loadedRatio: 0,
      // 页面加载完成
      curPageNum: 0,
      // 放大系数 默认百分百
      scale: 100,
      // 旋转角度 ‘90’的倍数才有效
      pageRotate: 0,
      // 单击内部链接时触发 (目前我没有遇到使用场景)
      page: 0,
      year: '2023',
      years: [
        2023,2022
      ],
      BaseUrl: 'https://img.bkgaoshou.com/pdf/',
      pdfUrl: '',
      keyWord: '',
      searchLIst: [],
      pdfData: [],
    };
  },
  created() {
    this.pdfUrl = this.BaseUrl + 2023 + '政策规定.pdf'
  },

  methods: {

    //放大
    scaleD() {
      this.scale += 5;
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    },

    //缩小
    scaleX() {
      // scale 是百分百展示 不建议缩放
      if (this.scale == 100) {
        return;
      }
      this.scale += -5;
      console.log(parseInt(this.scale) + "%");
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
    },
    // 切换上一页
    prePage() {
      var p = this.pageNum;
      p = p > 1 ? p - 1 : this.pageTotalNum;
      this.pageNum = p;
    },
    // 切换下一页
    nextPage() {
      var p = this.pageNum;
      p = p < this.pageTotalNum ? p + 1 : 1;
      this.pageNum = p;
    },
    // 页面加载成功  当前页数
    pageLoaded(e) {
      this.$emit("current", e);
      this.curPageNum = e;
      this.getContent()
    },
    // 异常监听
    pdfError(error) {
      console.error(error);
    },
    // 获取当前页面pdf的文字信息内容
    logContent() {
      this.$refs.pdf.pdf.forEachPage(function (page) {
        return page.getTextContent().then(function (content) {
          let text = content.items.map((item) => item.str);
          let allStr = content.items.reduce(
            (initVal, item) => (initVal += item.str),
            ""
          );
          console.log(allStr); // 内容字符串
          console.log(text); // 内容数组
        });
      });
    },

    onYearChange() {
      if (this.year) {
        this.pdfUrl = this.BaseUrl + this.year + '政策规定.pdf'
        console.log('ture')
      } else {
        this.$alert('请先选择年份', '提示', {
          confirmButtonText: '确定',
        });
      }
      console.log(this.year, this.batch)
    },
    getContent() {
      var that = this
      var i = 0;
      this.$refs.pdf.pdf.forEachPage(function (page) {
        return page.getTextContent().then(function (content) {
          // let text = content.items.map((item) => item.str);
          let allStr = content.items.reduce(
            (initVal, item) => (initVal += item.str),
            ""
          );
          that.pdfData[i] = allStr
          i++;
        });
      });
    },
    search() {
      console.log(1232312)
      var that = this
      let list = []

      this.pdfData.forEach((item, key) => {
        if (item.indexOf(this.keyWord) > -1) {
          list.push(key + 1)
        }
      })
      if (list[0]) {
        that.searchLIst = list
        that.pageNum = list[0]
      }
    },
    nestSearch() {
      if (this.searchLIst.indexOf(this.pageNum) > -1) {
        let page = this.searchLIst[this.searchLIst.indexOf(this.pageNum) + 1]
        if (page) {
          this.pageNum = page
        } else {
          this.pageNum = this.searchLIst[0]
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.page{
  padding: 30px 40px;
}
.pdf {
  margin-top: 20px;

  .show {
    overflow: auto;
    margin: auto;
    max-width: 98%;
    height: 80vh;
    // max-height: 530px;
  }

  .pdf_footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.5);

    .info {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 30%;
      }
    }
  }
}
</style>
