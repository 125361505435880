<template>
  <div>
    <div class="search-box">
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="6">
          <el-input v-model="check.name" class="condition-search" size="mini" prefix-icon="el-icon-search"
            placeholder="请输入院校名称或在豫招生代码" @keyup.enter.native="doSearch" />
        </el-col>
        <el-col :span="2" :offset="14">
          <el-button class="college-btn" type="primary" size="mini" @click="doSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <div v-if="condition" class="choose">
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">学历：</span>
          <span :class="{ item: true, act: check.degree === null }" @click="choose('degree', null, 0)">不限</span>
        </el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.degree === 0 }" @click="choose('degree', 0, 0)">本科</span>
          <span :class="{ item: true, act: check.degree === 1 }" @click="choose('degree', 1, 0)">专科</span>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">性质：</span>
          <span :class="{ item: true, act: check.nature.length === 0 }" @click="choose('nature', -1, 1)">不限</span>
        </el-col>
        <el-col :span="21">
          <span v-for="(item, i) in condition.nature" :key="i"
            :class="{ item: true, act: check.nature.indexOf(item.id) > -1 }" @click="choose('nature', item.id, 1)">{{
                item.name
            }}</span>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">特色：</span>
          <span :class="{ item: true, act: check.schoolTipId.length === 0 }"
            @click="choose('schoolTipId', -1, 1)">不限</span>
        </el-col>
        <el-col :span="21">
          <span v-for="(item, i) in condition.schoolTip" :key="i" :class="{
            item: true,
            act: check.schoolTipId.indexOf(item.id) > -1,
          }" @click="choose('schoolTipId', item.id, 1)">
            <el-badge v-if="i <= 4" class="items" value="本">{{ item.name }}
            </el-badge>
            <el-badge v-if="i > 4" class="items" value="专">{{ item.name }}
            </el-badge>
          </span>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">分类：</span>
          <span :class="{ item: true, act: check.school_type_id.length === 0 }"
            @click="choose('school_type_id', -1, 1)">不限</span>
        </el-col>
        <el-col :span="21">
          <span v-for="(item, i) in condition.schoolType" :key="i" :class="{
            item: true,
            act: check.school_type_id.indexOf(item.id) > -1,
          }" @click="choose('school_type_id', item.id, 1)">
            {{ item.name }}
          </span>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">批次：</span>
          <span :class="{ item: true, act: check.batch == null }" @click="choose('batch', null, 0)">全部</span>
        </el-col>
        <el-col :span="21">
          <span v-for="(item, i) in schoolBatch" :key="i" :class="{
            item: true,
            act: check.batch == item.id,
          }" @click="choose('batch', item.id, 0)">{{ item.name }}
          </span>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="3">
          <span class="class-name">属地：</span>
          <span :class="{ item: true, act: check.city_id.length === 0 }" @click="choose('city_id', -1, 1)">不限</span>
        </el-col>
        <el-col :span="21">
          <span v-for="(item, i) in condition.city" :key="i" :class="{
            item: true,
            act: check.city_id.indexOf(item.city_code) > -1,
          }" @click="choose('city_id', item.city_code, 1)">{{ item.city }}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionalSearch",
  props: ["condition"],
  data() {
    return {
      divisionList: [
        { id: "1", name: "文科综合", code: "1", status: "1" },
        { id: "2", name: "理科综合", code: "2", status: "1" },
      ],

      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
      ],
      schoolBatch: [
        {
          id: "1",
          name: "本科一批",
        },
        {
          id: "2",
          name: "本科二批",
        },
        {
          id: "3",
          name: "高职高专批",
        },
        {
          id: "4",
          name: "国家专项计划",
        },
        {
          id: "5",
          name: "地方专项计划",
        },
        {
          id: "12",
          name: "提前批体育",
        },

      ],

      check: {
        name: "",
        degree: null,
        nature: [],
        schoolTipId: [],
        school_type_id: [],
        city_id: [],
        batch: null
      },
      ExpertInfo: null,
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.$emit("onSearch", this.check);
  },
  methods: {
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
  },
};
</script>

<style lang="less" scoped>
.search-box {
  margin: 20px 0;

  .college-btn {
    padding: 5px 30px;
    font-size: 15px;
    display: block;
    margin: auto;
    border-radius: 60px;
  }
}

.el-badge__content.is-fixed {
  background-color: green;
}

.condition-search {
  border-radius: 10px;
}

.choose {
  border: 1px solid #e3e3e3;
  padding: 14px;
  font-size: 16px;

  .row {
    .class-name {
      display: inline-block;
      padding: 5px 0;
      color: #b8b8b8;
    }

    .item {
      display: inline-block;
      padding: 3px 8px;
      color: #b8b8b8;
      margin-bottom: 10px;
      cursor: pointer;
      margin-right: 10px;

      &.act {
        background-color: #0000ff;
        color: #ffffff;
      }

      .iconfont {
        color: #ea6f62;
        font-size: 22px;
        margin-right: 5px;
      }
    }
  }
}
</style>
