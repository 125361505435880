<template>
  <div style="padding-bottom: 40px" class="spcialty_score">
    <div class="main">
      <div>
        <el-row>
          <el-col :span="3">
            <el-select v-model="batch" placeholder="请选择批次" size="mini" @change="getScoreLine()">
              <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select v-model="division" placeholder="请选择科类" size="mini" @change="getScoreLine()">
              <el-option v-for="item in divisionList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select v-model="year" placeholder="选择年份" size="mini" @change="onYearChange">
              <el-option v-for="item in years" :key="item" :label="item + '年'" :value="item" />
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="table">
        <el-table v-if="tableData" :data="tableData" class="tab1" style="width: 100%" >
          <el-table-column prop="year" align="center" label="年份" width="55" />
          <el-table-column prop="specialty_id" align="center" label="代码" width="55">
            <template slot-scope="scope">
              <span>{{ scope.row.specialty_id | interpo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specialty_name" align="left" label="专业名称" width="251" />
          <el-table-column prop="pici" align="center" label="招生批次" />
          <el-table-column prop="max_score" align="center" width="65" label="最高分" />
          <el-table-column prop="mean_score" align="center" width="65" label="平均分" />
          <el-table-column prop="min_score" sortable align="center" width="95" label="最低分" />
          <el-table-column align="center" width="48" label="线差">
            <template slot-scope="scope">
              {{ scope.row.min_score - test }}
            </template>
          </el-table-column>
          <el-table-column prop="ranking" align="center" width="85" label="最低位次" />
          <el-table-column prop="matriculate_num" width="65" align="center" label="录取数" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialtyLine",
  props: ["id"],
  data() {
    return {
      tableData: [],
      division: "1",
      divisionList: [
        { id: "1", name: "文科综合", code: "1", status: "1" },
        { id: "2", name: "理科综合", code: "2", status: "1" },
      ],
      batch: "1",
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
        { id: "4", name: "其他批次", code: "4", status: "1" },
      ],
      value: null,
      name: "",
      category: "1", // 类别
      categoryList: [
        { id: "5", name: "软件类", code: "0", status: "1" },
        { id: "6", name: "正常收费", code: "1", status: "1" },
        { id: "7", name: "较高收费", code: "2", status: "1" },
        { id: "8", name: "少数民族", code: "3", status: "1" },
        { id: "9", name: "异地校区", code: "4", status: "1" },
        { id: "10", name: "医护类", code: "5", status: "1" },
        { id: "11", name: "农林矿", code: "6", status: "1" },
        { id: "12", name: "其他单列", code: "7", status: "1" },
        { id: "13", name: "定向", code: "8", status: "1" },
        { id: "14", name: "预科", code: "9", status: "1" },
      ], // 类别列表
      year: "2023", // 年份
      score: 580,
      years: [
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
      ],
      equateList: [],
      test: "",
    };
  },
  mounted() {
    if (this.$route.query.score) {
      this.score = this.$route.query.score;
    }
    this.getScoreLine();
  },
  methods: {
    choose(type, val) {
      if (this[type] === val) return false;
      this[type] = val;
      this.getScoreLine(this.id);
    },

    onYearChange() {
      let y = this.year;
      this.test = this.equateList[y];
      this.getScoreLine();
    },
    getScoreLine() {
      this.getEquate();
      this.$fecth
        .post("specialty_score/searchFormerYearData", {
          school_id: this.id,
          is_wenli: this.division,
          batch: this.batch,
          specific_code: this.category,
          year: this.year,
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            if (data == "" && this.year == 2021) {
              this.year = 2020;
              this.getScoreLine();
            }
            this.tableData = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    selectChange(val) {
      this.id = val;
      this.getScoreLine(val);
    },
    getEquate() {
      this.$fecth
        .get("Equate/searchPrecedence", {
          params: {
            is_wenli: this.division,
            class: this.batch,
          },
        })
        .then((res) => {
          const { code, data } = res;
          if (code == 200) {
            this.equateList = data;
            let y = this.year;
            this.test = this.equateList[y];
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 90%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;

  .title {
    padding-left: 40px;
    margin-top: 30px;
    font-size: 36px;
    color: #555555;

    .label {
      font-size: 20px;
      margin: 0 20px;
    }

    .radio {
      margin-left: 20px;
      color: #7ca4c3;
      font-size: 20px;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .table {
    margin: 40px 0;
  }
}

.table {
  .tab1 {
    background: url("../../../assets/img/logo.png");
  }
}
</style>

<style lang="less" >
.spcialty_score {

  .el-table th,
  .el-table tr {
    background: none;
  }
}
</style>
