<template>
  <div class="body">
    <div class="header">
      <div class="left">
        <router-link :to="{
          path: '/'
        }">
          <div class="logo">

            <el-image style="margin-right: 5px;" :src="require('./../../assets/new_icon/back.png')"></el-image>
            <span>
              返回学员列表
            </span>
          </div>
        </router-link>
      </div>

      <div class="right">
        <ul>
          <li :class="{ act: act == 0 }" @click="getComponent('zengce')">政策资讯</li>
          <li :class="{ act: act == 9 }" @click="getComponent('SearchCollege')">院校查询</li>
          <li :class="{ act: act == 8 }" @click="getComponent('searchSpecialty')">专业查询</li>
          <li :class="{ act: act == 2 }" @click="getComponent('collegeScore')">院校数据</li>
          <li :class="{ act: act == 1 }" @click="getComponent('specialtyScore')">专业数据</li>
          <li :class="{ act: act == 3 }" @click="getComponent('plan')">招生计划</li>
          <li :class="{ act: act == 4 }" @click="getComponent('tiqianpiItem')">提前批数据</li>
          <li :class="{ act: act == 5 }">测评报告</li>
          <li :class="{ act: act == 6 }">志愿填报</li>
          <li :class="{ act: act == 7 }" @click="getComponent('report')">志愿列表</li>
        </ul>
      </div>
    </div>

    <div class="nav">
      <div class="left">
        <span>科类：<span class="i-color">
            <span> {{ userInfo.subject == 1 ? '物' : '史' }}<span v-for=" (item, key) in JSON.parse(other_subject) "
                :key="key">
                <span v-if="item == 2">化</span>
                <span v-if="item == 3">生</span>
                <span v-if="item == 6">政</span>
                <span v-if="item == 5">地</span>
              </span>
            </span>
          </span>
        </span>
        <span>分数：<span class="i-color">{{ userInfo.score }}</span></span>
        <span>位次：<span class="i-color">{{ rankingData.ranking }}</span></span>

      </div>

      <div class="right">
        <ul>
          <li :class="{ nav_act: nav_act == 0 }">本科提前批</li>
          <li :class="{ nav_act: nav_act == 1 }"> 高职高专提前批</li>
          <li :class="{ nav_act: nav_act == 2 }">
            <router-link :to="{
              path: '/tianbao', query: {
                id: user_id
              }
            }">
              本科普通批次</router-link>
          </li>
          <li :class="{ nav_act: nav_act == 3 }">
            <!-- <router-link :to="{
              path: '/tianbao/zhuanke', query: {
                id: user_id
              }
            }"> -->
            高职高专普通批
            <!-- </router-link> -->
          </li>
        </ul>
      </div>
    </div>
    <router-view v-if="rankingData.ranking > 0 && userInfo.user_name" :userInfo="userInfo" :updateStatus="updateStatus"
      :rankingData="rankingData" />

    <el-drawer title="我是标题" :visible.sync="drawerState" v-if="drawerState" :with-header="false" size="1220px"
      :before-close='changeReport'>
      <component v-bind:is="currentTabComponent"></component>
    </el-drawer>
  </div>
</template>

<script>
import report from "./report/index.vue";
import zengce from "../pdf_plan/zhengce";
import plan from "../pdf_plan/plan";
import specialtyScore from "./specialty/index.vue";
import collegeScore from "./college/index.vue";
import tiqianpiItem from "./advanceBatch/index.vue";
import searchSpecialty from "../search-specialty/SearchSpecialty.vue";
import SearchCollege from "../search-college/index.vue";

export default {
  name: '',
  data() {
    return {
      act: 6,
      nav_act: 2,
      userInfo: {
        user_name: null
      },
      rankingData: {
        ranking: 0,
        ranking_1: 0,
        ranking_2: 0,
        ranking_3: 0,
      },
      user_id: '',
      other_subject: null,

      updateStatus: 1,
      drawerState: false,

      currentTabComponent: null
    };
  },
  components: {
    report, zengce, plan, specialtyScore, collegeScore, tiqianpiItem, searchSpecialty, SearchCollege
  },
  created() {
    this.user_id = this.$route.query.id
    this.getUserInfo(this.user_id);
  },
  methods: {
    //获取用户位次信息
    getRanking(userInfo) {
      this.$fecth.post("user/getRanking", { subject: userInfo.subject, score: userInfo.score }).then((res) => {
        if (res.data) {
          this.rankingData = res.data
        }
      });
    },
    //获取用户信息
    getUserInfo(id) {
      //获取用户信息
      this.$fecth.post("user/getUserInfo", { id: id }).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.userInfo = data
          this.other_subject = this.userInfo.other_subject

          //获取位次信息
          this.getRanking(data)
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },

    //选择模块
    getComponent(item) {
      this.drawerState = true
      this.currentTabComponent = item
    },

    //更新志愿表
    changeReport() {
      if (this.currentTabComponent == 'report') {
        this.updateStatus++
      }
      this.drawerState = false
    },
  },
};
</script>

<style scoped lang='less'>
.body {
  width: 1400px;
  margin: 0 auto;
  background: #E8F7FB;
  font-size: 18px;
}

.header {
  width: 1400px;
  padding: 20px 60px;
  background: white;

  .left {

    a {
      color: black;
    }

    display: inline-block;
  }

  .right {
    float: right;

    ul {
      display: inline-block;
      list-style: none;
    }

    li {
      float: left;
      margin-left: 20px;

    }
  }

  .act {
    color: #438FE2;
    border-bottom: 1px solid #438FE2;
  }

  .logo {
    display: inline-block;
    display: flex;
    align-items: center;

  }
}

.nav {
  margin-top: 7px;
  width: 1400px;
  padding: 20px 60px;
  background: white;
  border-bottom: 2px solid #459EFF;
  padding-bottom: 10px;

  .left {
    display: inline-block;
    position: relative;
    top: 4px;


    .i-color {
      color: #459EFF;
      margin-right: 15px;
    }
  }

  .right {
    float: right;

    ul {
      display: inline-block;
      list-style: none;
    }

    li {
      float: left;
      margin-left: 10px;
      height: 32px;
      padding: 17px;
      line-height: 3px;
      border-radius: 5px 5px 0 0;

      a {
        color: black;
      }
    }

    li:hover {
      color: white;
      background: #459EFF;
      border-bottom: 1px solid #459EFF;

      a {
        color: white !important;
      }
    }


  }

  .nav_act {
    color: white;
    background: #459EFF;
    border-bottom: 1px solid #459EFF;

    a {
      color: white !important;
    }
  }
}
</style>
