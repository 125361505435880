<template>
  <div class="page">
    <div class="item">
      <conditional-search @onSearch='onSearch' />
    </div>

    <el-table :data="collegeList" class="tab_plan"
      :header-cell-style="{ background: '#F4F7FC', color: '#555', padding: '3px 0', 'text-align': 'center' }"
      :cell-style="{ background: 'white', padding: '11px 0' }" style="width: 100%" border v-loading='loading'>
      <el-table-column prop="school_id" label="院校代码">
      </el-table-column>
      <el-table-column prop="name" label="院校名称" show-overflow-tooltip width="180px">
      </el-table-column>
      <el-table-column label="2024">
        <el-table-column prop="min_score_1" width="65px" label="最低分" align="center">
        </el-table-column>
        <el-table-column prop="difference_1"  width="65px" label="线差" align="center">
        </el-table-column>
        <el-table-column prop="ranking_1" label="最低位次" align="center">
        </el-table-column>
      </el-table-column>

      <el-table-column label="2023">
        <el-table-column prop="min_score_2"  width="65px" label="最低分" align="center">
        </el-table-column>
        <el-table-column prop="difference_2"  width="65px" label="线差" align="center">
        </el-table-column>
        <el-table-column prop="ranking_2" label="最低位次" align="center">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2022">
        <el-table-column prop="min_score_3"  width="65px" label="最低分" align="center">
        </el-table-column>
        <el-table-column prop="difference_3"  width="65px" label="线差" align="center">
        </el-table-column>
        <el-table-column prop="ranking_3" label="最低位次" align="center">
        </el-table-column>
      </el-table-column>
      <el-table-column prop="nature" label="办学性质" align="center">
      </el-table-column>
      <el-table-column prop="city_name" label="所在地" align="center">
      </el-table-column>
      <el-table-column prop="school_type" label="院校类型" align="center"> </el-table-column>
    </el-table>

    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="this.page.pageSize"
            layout=" prev, pager, next" :total="this.page.total" :current-page.sync="page.currentPage">
          </el-pagination>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import ConditionalSearch from "./components/ConditionalSearch";

export default {
  name: '',
  components: {
    ConditionalSearch
  },

  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
        currentPage: 1,
      },
      check: {
        city_id: [],
        nature_id: null,
        school_type_id: [],
        schoolTipId: [],
        min_ranking: null,
        max_ranking: null,
        name: "",
        subject: 1
      },
      loading: true,
      collegeList: []
    };
  },
  created() {
    this.getCollegeList()
  },
  methods: {
    onSearch(e) {
      this.check = e;
      this.page.pageNum = 1
      this.page.currentPage = 1
      this.getCollegeList();
    },
    //翻页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getCollegeList();
    },
    getCollegeList() {
      this.loading = true
      this.$fecth
        .post("show_school_score/getCollegeExpert", {
          city_id: this.check.city_id,
          name: this.check.name,
          nature_id: this.check.nature_id,
          schoolTipId: this.check.schoolTipId,
          school_type_id: this.check.school_type_id,
          subject: this.check.subject,
          min_ranking: this.check.min_ranking,
          max_ranking: this.check.max_ranking,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,

        })
        .then((res) => {
          console.log(res.data)
          this.loading = false
          const { code, msg, data } = res;
          if (code == 200) {
            this.collegeList = data.lists;
            this.page.total = data.count;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-width: 1000px;
  padding: 30px 40px;
  background: white;
}
</style>
