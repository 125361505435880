<template>
  <div class="page">
    <div class="item">
      <conditional-search @onSearch='onSearch' />
    </div>

    <el-table :data="specialtyListData" class="tab_plan"
      :header-cell-style="{ background: '#F4F7FC', color: '#555', padding: '23px 0' }"
      :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
      <el-table-column prop="name" label="院校名称" width="350px" show-overflow-tooltip>
        <template slot-scope="scope">
          <div style="padding: 10px 20px;">
            <div style="font-size: 18px;font-weight: 600;margin-bottom: 8px;">{{ scope.row.name }}</div>
            <div style="color: #A7A7A7;">
              <span>{{ scope.row.school_type }}</span>
              <span style="padding: 0 5px;">|</span>
              <span>{{ scope.row.city_name }}</span>
              <span style="padding: 0 5px;">|</span>
              <span>{{ scope.row.nature }}</span>
              <span style="padding: 0 5px;">|</span>
              <span>{{ scope.row.school_id }}</span>

            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="specialty_name" label="专业名称" width="220px" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="plan_num" label="招生计划" align="center">
      </el-table-column>
      <el-table-column prop="matriculate_num" label="录取" align="center">
      </el-table-column>
      <el-table-column prop="max_score" label="最高分" align="center">
      </el-table-column>
      <el-table-column prop="mean_score" label="平均分" align="center">
      </el-table-column>
      <el-table-column prop="min_score" label="最低分" align="center">
      </el-table-column>
      <el-table-column prop="ranking" label="最低位次" align="center"> </el-table-column>
    </el-table>

    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="this.page.pageSize"
            layout=" prev, pager, next" :total="this.page.total" :current-page="this.currentPage">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ConditionalSearch from "./components/ConditionalSearch";
export default {
  name: '',
  components: {
    ConditionalSearch
  },
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      check: {
        degree: null,
        city_id: [],
        nature_id: null,
        school_type_id: [],
        schoolTipId: [],
        name: '河南大学',
        year: 2024,
        specialty_name: null,
        subject: 1
      },
      specialtyListData: [],
      currentPage: 1,
      loading: false
    };
  },

  computed: {
  },
  created() {
    this.getSpecialtyData()
  },
  methods: {
    onSearch(e) {
      this.check = e;
      this.getSpecialtyData();
    },
    //翻页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getSpecialtyData();
    },
    getSpecialtyData() {
      if (this.check.name != '' || this.check.specialty_name != '') {
        this.loading = true
        this.$fecth
          .post("specialty_score/getSpecialtyScore", {
            city_id: this.check.city_id,
            name: this.check.name,
            nature_id: this.check.nature_id,
            schoolTipId: this.check.schoolTipId,
            school_type_id: this.check.school_type_id,
            specialty_name: this.check.specialty_name,
            year: this.check.year,
            subject: this.check.subject,
            pageNum: this.page.pageNum,
            pageSize: this.page.pageSize,

          })
          .then((res) => {
            console.log(res.data)
            this.loading = false
            const { code, msg, data } = res;
            if (code == 200) {
              this.specialtyListData = data.lists;
              this.page.total = data.count;
            } else {
              this.showTips(0, msg);
            }
          });
      }

    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-width: 1000px;
  padding: 30px 40px;
  background: white;
}
</style>
