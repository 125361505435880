<template>
  <div class="page-search-speicalty">
    <div class="main">
      <div class="tabs">
        <span class="title">专业层次></span>
        <span :class="{ tab: true, act: degree === 0 }" @click="getLevel(0)">本科</span>
        <span :class="{ tab: true, act: degree === 1 }" @click="getLevel(1)">专科</span>
        <span :class="{ tab: true, act: degree === 3 }" @click="getLevel(3)">特色专业建设名单</span>
        <span :class="{ tab: true, act: degree === 2 }" @click="getLevel(2)">学科评估(2017)</span>
        <div v-if="assess_">
          <el-divider></el-divider>
          <span class="title"> 学科门类> </span>

          <span v-for="(item, i) in level" :class="{ item: true, act: levelId === item.specialty_code }" :key="i" @click="getChildren(item.specialty_code)">
            {{ item.specialty_code }}{{ item.name }}</span>
        </div>
      </div>

      <div class="specialty" v-if="assess_">
        <div v-if="children.length > 0">
          <div v-for="(item, i) in children" :key="i">
            <div class="title-s">{{ item.specialty_code }}{{ item.name }}</div>
            <div v-if="item.childrens.length > 0" class="class-box">
              <div v-for="(specialty, k) in item.childrens" :key="k" class="item" @click="getSpecialtyDesc(specialty.specialty_code, degree)">
                {{ specialty.name }}
              </div>
            </div>
            <div v-else class="class-box">
              <span class="item">暂无相关专业</span>
            </div>
          </div>
        </div>
        <div v-else class="class-box">
          <span class="item">暂无相关专业</span>
        </div>
      </div>

      <div v-else>
        <div v-if="degree === 2">
          <p class="intro">
            学科评估是教育部学位与研究生教育发展中心（简称学位中心）按照国务院学位委员会和教育部颁布的《学位授予与人才培养学科目录》（简称学科目录）对全国具有博士或硕士学位授予权的一级学科开展整体水平评估。学科评估是学位中心以第三方方式开展的非行政性、服务性评估项目，2002年首次开展，截至2017年完成了四轮。
            第四轮学科评估于2016年4月启动，按照“自愿申请、免费参评”原则，采用“客观评价与主观评价相结合”的方式进行。评估体系在前三轮的基础上进行诸多创新；评估数据以“公共数据和单位填报相结合”的方式获取；评估结果按“分档”方式呈现，具体方法是按“学科整体水平得分”的位次百分位，将前70%的学科分9档公布：前2%（或前2名）为A+，2%～5%为A（不含2%，下同），5%～10%为A-，10%～20%为B+，20%～30%为B，30%～40%为B-，40%～50%为C+，50%～60%为C，60%～70%为C-。
            公布评估结果旨在为参评单位了解学科优势与不足、促进学科内涵建设、提高研究生培养质量提供客观信息；为学生选报学科、专业提供参考；同时也便于社会各界了解我国高校和科研单位学科内涵建设的状况和成效。
          </p>
          <div style="
              width: 20%;
              display: inline-block;
              vertical-align: top;
              font-size: 16px;
            ">
            <el-menu default-active="2" class="el-menu-vertical-demo" unique-opened>
              <el-submenu v-for="(item, i) in assess" :key="i" :index="toString(i)">
                <template slot="title">
                  <span class="font-16">{{ item.name }}</span>
                </template>
                <el-menu-item v-for="(val, key) in item.childrens" :index="childString(i, key)" :key="key" class="font-16" @click="getAssesData(val.code)">{{ val.name }}</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
          <div style="
              width: 75%;
              display: inline-block;
              margin-left: 5%;
              vertical-align: top;
            ">
            <el-table :data="tableData" border style="width: 100%; font-size: 16px">
              <el-table-column type="index" label="序号" width="60" />
              <el-table-column prop="scode" label="学校代码" width="180" />
              <el-table-column prop="sname" label="学校名称" width="180" />
              <el-table-column prop="result" label="评选结果" />
            </el-table>
          </div>
          <div style="text-align: right; color: gray; margin-top: 20px">
            *引用于教育部
          </div>
        </div>

        <div v-if="degree === 3">
          <p class="intro">
            特色专业是指一所学校的某一专业，在教育目标、师资队伍、课程体系、教学条件和培养质量等方面，具有较高的办学水平和鲜明的办学特色，已产生较好的办学效益和社会影响，是一种高标准、高水平、高质量的专业。我国“十一五”期间将择优重点建设3000个左右的特色专业建设点。
          </p>
          <div style="
              width: 20%;
              display: inline-block;
              vertical-align: top;
              font-size: 16px;
            ">
            <el-menu default-active="1" class="el-menu-vertical-demo" unique-opened @select="handleSelects">
              <el-menu-item index="1">
                <span class="font-16">第一批</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span class="font-16">第二批</span>
              </el-menu-item>
              <el-menu-item index="3">
                <span class="font-16">第三批</span>
              </el-menu-item>
              <el-menu-item index="4">
                <span class="font-16">第四批</span>
              </el-menu-item>
              <el-menu-item index="5">
                <span class="font-16">第五批</span>
              </el-menu-item>
              <el-menu-item index="6">
                <span class="font-16">第六批</span>
              </el-menu-item>
              <el-menu-item index="7">
                <span class="font-16">第七批</span>
              </el-menu-item>
            </el-menu>
          </div>

          <div style="
              width: 75%;
              display: inline-block;
              margin-left: 5%;
              vertical-align: top;
            ">
            <el-table :data="test" border style="width: 100%; font-size: 16px">
              <el-table-column type="index" label="序号" width="60" />
              <el-table-column prop="name" label="学校代码" width="180" />
              <el-table-column prop="specialty" label="专业名称" />
              <el-table-column prop="school_id" label="在豫招生代码" width="140" />
              <el-table-column prop="specialty_id" label="专业代码（不可用于填报）" width="140" />
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" :append-to-body="true" width="1000px" v-if="SpecialtyOverview">
      <div class="top">
        <span>专业名称:{{SpecialtyOverview.name}}</span>
        <span>学历层次:{{ SpecialtyOverview.degree==0?'本科':'专科' }}</span>
        <span>修学年限:{{ SpecialtyOverview.study_year }}</span>
        <span>授予学位:{{ SpecialtyOverview.qualification }}</span>
        <span>文理比例:{{ SpecialtyOverview.wenli_proportion }}</span>
        <span>男女比例:{{ SpecialtyOverview.nannv_proportion }}</span>
      </div>
      <div class="bottom">

        <div class="items">
          <div class="title">专业介绍</div>
          <div class="breif">{{ SpecialtyOverview.introduction }}</div>
        </div>
        <div class="items">
          <div class="title">专业背景</div>
          <div class="breif">{{ SpecialtyOverview.introduction }}</div>
        </div>
        <div class="items">
          <div class="title">培养目标</div>
          <div class="breif">{{ SpecialtyOverview.train_objective }}</div>
        </div>
        <div class="items">
          <div class="title">学习门槛</div>
          <div class="breif">{{ SpecialtyOverview.discipline_required }}</div>
        </div>
        <div class="items">
          <div class="title">考研方案</div>
          <div class="breif">{{ SpecialtyOverview.pg_direction }}</div>
        </div>
        <div class="items">
          <div class="title">主要课程</div>
          <div class="breif">{{ SpecialtyOverview.train_requirements }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "LookSpecialty",
  data() {
    return {
      search: "",
      degree: 0,
      kwd: "",
      level: [],
      levelId: 0,
      children: [],
      assess: [],
      assess_: true,
      tableData: [],
      test: [],
      tszy: 1,
      dialogVisible: false,
      SpecialtyOverview: [],
    };
  },
  created() {
    this.getLevel(0);
  },
  methods: {
    getLevel(id) {
      this.degree = id;
      if (id == 2) {
        this.assess_ = false;
        this.getAssessList();
        this.getAssesData(301);
      } else if (id == 3) {
        this.getTszy();
        this.assess_ = false;
      } else {
        this.assess_ = true;

        this.$fecth
          .post("Specialty/getSpecialty", { degree: id })
          .then((res) => {
            const { code, msg, data } = res;
            if (code == 200) {
              this.level = data;
              this.getChildren(data[0].specialty_code);
            } else {
              this.showTips(0, msg);
            }
          });
      }
    },
    getChildren(id) {
      this.levelId = id;
      this.$fecth
        .post("Specialty/getSpecialtyChild", {
          specialty_code: id,
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.children = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    toSearch() {
      this.$router.push({ path: "/search", query: { kwd: this.kwd, type: 1 } });
    },
    getAssessList() {
      this.$fecth.post("assess/getList").then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.assess = data;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    toString(i) {
      return String(i);
    },
    childString(i, k) {
      return i + "-" + k;
    },
    getAssesData(code) {
      this.$fecth.post("assess/getAssessData", { code: code }).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tableData = data;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    getTszy() {
      this.$fecth.post("tszy/getData", { type: this.tszy }).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.test = data;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleSelects(key) {
      this.tszy = key;
      this.getTszy();
    },
    getSpecialtyDesc(specialty_code, degree) {
      this.dialogVisible = true;
      this.$fecth
        .post("Specialty/getSpecialtyOverview", {
          specialty_code: specialty_code,
          degree: degree,
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.SpecialtyOverview = data;
          } else {
            this.showTips(0, msg);
          }
        });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="less" scoped>

.page-search-speicalty {
  width: 1200px;
  background: white;
  margin: 0 auto;
  padding: 20px 50px 80px;
}
.tabs {
  color: #6c6b6b;
  border: 1px solid #dedfe0;
  padding: 25px;
  .title {
    color: #000000;
    font-weight: 600;
  }
  span {
    display: inline-block;
    padding: 0 15px;
    line-height: 35px;
    &:hover {
      background: #2b7bf3;
      color: white;
    }
  }
}
.act {
  background: #2b7bf3;
  color: #ffffff !important;
}
.specialty {
  .title-s {
    margin: 20px 0;
    line-height: 35px;
    background: #f2f2f2;
    border-left: 2px solid #2b7bf3;
    text-indent: 1rem;
  }
  .item {
    display: inline-block;
    padding: 0px 40px;
    line-height: 40px;
    text-decoration: none;
    color: #000;
    &:hover {
      background: #2b7bf3;
      color: white;
    }
  }
}
</style>


<style lang="less" scoped>
.top {
  span {
    margin-right: 50px;
  }
}
.items {
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(221, 216, 216);
  .title {
    line-height: 30px;
  }
}
</style>